<script>
import Layout from "../../layouts/main";
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";
import store from "@/state/store";
var state = store._modules.root._children.auth.state;
export default {
  page: {
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
  },
  data() {
    return {
      coupon_deatiles: [],
      couponTypes: [
        { id: 1, name: "Percantge From Customer Purchases", value: 0 },
        { id: 2, name: "Fixed Amount From Customer Purchases", value: 1 },
      ],
      hasShippings: [
        { id: 1, name: "No", value: 0, name_en: "لا" },
        { id: 2, name: "Yes", value: 1, name_en: "نعم" },
      ],
      isPercintage: false,
      coupon: {},
      coupon_discounts: [],
      offer_details: [],

      Coupons: [],
      page: 1,
      limit: 10,
      tot_pages: 0,
      searchCoupons: null,
      CouponsSearchMode: false,
      searchModel: "",
      state: state,
      loading: false,
    };
  },
  methods: {
    responseAlert(text, title, icon) {
      this.$swal.fire({
        title: `${title}`,
        text: ` ${text} `,
        icon: `${icon}`,
        toast: true,
        position: "top-start",
        showConfirmButton: false,
        timer: 2000,
      });
    },
    addCoupons() {
      this.http.post("coupons", this.coupon).then(() => {
        this.get(this.page);
      });
    },
    editCoupons(app) {
      var data = {
        title: "popups.coupons",
        inputs: [
          { model: "name", type: "text", label: "code", value: app.name },
          {
            model: "copons_status_id",
            type: "text",
            label: "copons_status_id",
            value: app.copons_status_id,
          },
          {
            model: "end_date",
            type: "text",
            label: "end_date",
            value: app.end_date,
          },
        ],
        buttons: [
          {
            text: "popups.edit",
            closer: true,
            color: "primary",
            handler: (obj) => {
              this.http.put("coupons", app.id, obj).then(() => {
                this.get(this.page);
              });
            },
          },
          {
            text: "popups.cancel",
            closer: true,
            color: "danger",
            handler: () => {
              console.log("Like Clicked");
            },
          },
        ],
      };
      this.popup.modal(data);
    },
    showPercintage(event) {
      this.coupon.type = event.target.value;
      if (event.target.value == 0) {
        this.isPercintage = true;
      } else {
        this.isPercintage = false;
      }
    },
    search() {
      this.loading = true;
      if (this.searchModel == "") {
        this.cancelappsearchMode();
        return;
      }
      this.CouponsSearchMode = true;
      this.tot_pages = 0;
      this.http
        .post("coupons/search", {
          search: this.searchModel,
          limit: this.limit,
          page: this.page,
          col: "name",
        })
        .then((res) => {
          if (res.status) {
            this.loading = false;
            this.Coupons = res.data;
          }
          console.log("######## files", this.Coupons);
        });
    },
    cancelappsearchMode() {
      this.searchModel = "";
      this.CouponsSearchMode = false;
      this.get(this.page);
    },

    getCoupons() {
      this.http.get("coupons").then((res) => {
        this.Coupons = res.data;
      });
    },
    deleteCoupons(app) {
      var data = {
        title: "popups.deletefile",
        msg: "popups.delmessage",
      };
      this.popup.confirm(data).then((resp) => {
        if (resp) {
          console.log("###");
          this.http.delete("coupons", app.id).then((res) => {
            if (res.status) {
              if (this.$i18n.locale == "ar") {
                this.responseAlert("تم حذف الكوبون بنجاح", " تم ", "success");
              } else {
                this.responseAlert(
                  "Coupon hass been deleted successfully",
                  "done",
                  "success"
                );
              }
            } else {
              if (this.$i18n.locale == "ar") {
                this.responseAlert(
                  "حدث خطأ اثناء حذف الكوبون",
                  "خطأ",
                  "warning"
                );
              } else {
                this.responseAlert("something went wrong", "error", "warning");
              }
            }
            console.log("############################3");
            this.get(this.page);
          });
        }
      });
    },

    get(page) {
      this.loading = true;
      console.log(page);
      this.http
        .post("coupons/paginate", {
          limit: this.limit,
          page: page,
        })
        .then((res) => {
          if (res.status) {
            this.loading = false;
            this.page = page;
            this.tot_pages = Math.ceil(res.tot / this.limit);
            this.Coupons = res.data;
          }
        });
    },
  },
  created() {
    this.get(1);
  },
};
</script>

<template>
  <Layout>
    <PageHeader
      :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'"
      :title="$t('menu.menuitems.coupons.text')"
    />
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row mb-2">
              <div class="col-sm-4">
                <div class="search-box me-2 mb-2 d-inline-block">
                  <div class="position-relative">
                    <input
                      @keyup="search()"
                      type="text"
                      class="form-control"
                      :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'"
                      :placeholder="$t('popups.search')"
                      v-model="searchModel"
                    />
                    <i class="bx bx-search-alt search-icon"></i>
                  </div>
                </div>
              </div>
              <div
                class="col-sm-8"
                style="display: flex; justify-content: flex-end"
              >
                <router-link to="add-coupon">
                  <button class="btn btn-success btn-rounded mb-2 me-2">
                    <i class="mdi mdi-plus me-1"></i>
                    {{ $t("coupons.new.title") }}
                  </button>
                </router-link>
              </div>
            </div>
            <!-- <div class="row"> -->
            <!-- <div class="col-8 md:col-6">
                <div class="search-box chat-search-box">
                  <div class="position-relative">
                    <input
                      @keyup="search()"
                      type="text"
                      class="form-control fa-lg text-light"
                      :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'"
                      :placeholder="$t('popups.search')"
                      v-model="searchModel"
                      style="background-color: #2a3042 !important"
                    />
                    <i class="bx bx-search-alt search-icon text-light"></i>
                  </div>
                </div>
              </div>
              <div
                class="col-4 md:col-6"
                style="display: flex; justify-content: flex-end"
              >
                <button
                  class="btn-close text-light"
                  v-if="CouponsSearchMode"
                  @click="cancelappsearchMode()"
                >
                  X
                </button>
                <button
                  type="button"
                  data-bs-toggle="modal"
                  data-bs-target="#addModal"
                  class="btn btn-light float-end mb-4"
                >
                  <span
                    class="bx bxs-plus-circle float-end fa-2x"
                    style="color: #2a3042 !important"
                  ></span>
                </button>
              </div> -->
            <!-- </div> -->

            <div class="table-responsive">
              <table
                :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'"
                class="table table-centered table-nowrap table-striped table-hover align-middle"
              >
                <thead>
                  <tr
                    class="text-light"
                    style="background-color: #2a3042 !important"
                  >
                    <th scope="col">#</th>
                    <th scope="col">{{ $t("coupons.name") }}</th>
                    <th scope="col">{{ $t("coupons.code") }}</th>
                    <th scope="col">{{ $t("coupons.type") }}</th>
                    <th scope="col">{{ $t("coupons.copons_status_id") }}</th>
                    <th scope="col">{{ $t("coupons.end_date") }}</th>
                    <!-- <th scope="col">{{ $t("coupons.created") }}</th> -->
                    <!-- <th scope="col">{{ $t("coupons.updated") }}</th> -->
                    <th scope="col">{{ $t("coupons.operations") }}</th>
                  </tr>
                </thead>
                <tbody v-if="!loading">
                  <tr v-for="(app, index) in Coupons" :key="app" class="">
                    <td>{{ index + 1 }}</td>
                    <td>{{ app.name }}</td>
                    <td>{{ app.code }}</td>
                    <td>
                      {{
                        app?.coupon_deatiles[0]?.percantge
                          ? couponTypes[0]?.name
                          : couponTypes[1]?.name
                      }}
                    </td>
                    <!-- <td>{{ app?.copons_status?.name }}</td> -->
                    <td>Active</td>
                    <td>{{ app.end_date?.split("T")[0] }}</td>
                    <!-- <td>{{ app.created.split("T")[0] }}</td> -->
                    <!-- <td>{{ app.updated.split("T")[0] }}</td> -->

                    <td>
                      <router-link :to="'/edit-coupon/' + app?.id">
                        <button
                          class="btn btn-success"
                          style="margin-inline-end: 4px"
                          href="javascript: void(0);"
                          role="button"
                        >
                          {{ $t("popups.edit") }}
                        </button>
                      </router-link>
                      <button
                        style="margin-inline-end: 4px"
                        class="btn btn-danger"
                        href="javascript: void(0);"
                        role="button"
                        @click="deleteCoupons(app)"
                      >
                        {{ $t("popups.delete") }}
                      </button>
                      <router-link :to="'/coupons/coupon/' + app?.id">
                        <button
                          class="btn btn-info"
                          role="button"
                          data-toggle="tooltip"
                          data-placement="top"
                          :title="app.name + ' ' + $t('products.profile')"
                        >
                          <i class="bx bxs-detail"></i>
                        </button>
                      </router-link>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <Loader class="mt-4" v-if="loading" :loading="loading" />
            <ul class="pagination pagination-rounded justify-content-end mb-2">
              <li class="page-item" :class="{ disabled: page == 1 }">
                <a
                  class="page-link"
                  href="javascript: void(0);"
                  @click="get(page - 1)"
                  aria-label="Previous"
                >
                  <i class="mdi mdi-chevron-left"></i>
                </a>
              </li>
              <li
                class="page-item"
                :class="{ active: p == page }"
                v-for="p in tot_pages"
                :key="p"
              >
                <a
                  class="page-link"
                  href="javascript: void(0);"
                  @click="get(p)"
                  >{{ p }}</a
                >
              </li>

              <li class="page-item" :class="{ disabled: page == tot_pages }">
                <a
                  class="page-link"
                  href="javascript: void(0);"
                  @click="get(page + 1)"
                  aria-label="Next"
                >
                  <i class="mdi mdi-chevron-right"></i>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <!-- end  table -->

    <!--edit customer-->
    <div
      class="modal fade"
      id="addModal"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <div class="container px-0">
              <form @submit.prevent="addCoupons()">
                <div class="modal-header">
                  <h5 class="modal-title mt-3" id="exampleModalLabel">
                    {{ $t("popups.add") }}
                  </h5>
                  <button
                    type="button"
                    class="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    style="position: relative; left: 10px"
                  ></button>
                </div>
                <div class="mb-3 mt-4">
                  <label for="exampleInputEmail1" class="form-label">
                    {{ $t("coupons.name") }}
                  </label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="coupon.name"
                  />
                </div>
                <div class="mb-3 mt-4">
                  <label for="exampleInputEmail1" class="form-label">
                    {{ $t("coupons.code") }}
                  </label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="coupon.code"
                  />
                </div>

                <div class="mb-3">
                  <label for="exampleInputPassword1" class="form-label">
                    {{ $t("coupons.type") }}
                  </label>
                  <select
                    @change="showPercintage($event, coupon)"
                    class="form-select"
                    aria-label="Default select example"
                    v-model="coupon.type"
                  >
                    <option v-for="i in couponTypes" :key="i" :value="i.value">
                      {{ i.name }}
                    </option>
                  </select>
                </div>
                <div>
                  <div class="mb-3">
                    <label for="exampleInputPassword1" class="form-label">
                      {{
                        isPercintage
                          ? $t("coupons.percant")
                          : $t("coupons.amount")
                      }}
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      v-model="coupon.percantge_value"
                    />
                  </div>
                  <div class="mb-3">
                    <label for="exampleInputPassword1" class="form-label">
                      {{ $t("coupons.descount_amount") }}
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      v-model="coupon.percantge_price"
                    />
                  </div>
                </div>
                <div class="mb-3 mt-4">
                  <label for="exampleInputEmail1" class="form-label">
                    {{ $t("coupons.end_date") }}
                  </label>
                  <input
                    type="date"
                    class="form-control"
                    v-model="coupon.end_date"
                  />
                </div>
                <div class="mb-3 mt-4">
                  <label for="exampleInputEmail1" class="form-label">
                    {{ $t("coupons.use_count") }}
                  </label>
                  <input
                    type="number"
                    class="form-control"
                    v-model="coupon.use"
                  />
                </div>
                <div class="mb-3 mt-4">
                  <label for="exampleInputEmail1" class="form-label">
                    {{ $t("coupons.customer_count") }}
                  </label>
                  <input
                    type="number"
                    class="form-control"
                    v-model="coupon.use_per_custmor"
                  />
                </div>
                <div class="mb-3 mt-4">
                  <label for="exampleInputEmail1" class="form-label">
                    {{ $t("coupons.min_pruchers_number") }}
                  </label>
                  <input
                    type="number"
                    class="form-control"
                    v-model="coupon.min_pruchers_number"
                  />
                </div>
                <div class="mb-3">
                  <label for="exampleInputPassword1" class="form-label">
                    {{ $t("coupons.has_shipping") }}
                  </label>
                  <select
                    class="form-select"
                    aria-label="Default select example"
                    v-model="coupon.has_shiping"
                  >
                    <option
                      :selected="i.value == 1"
                      v-for="i in hasShippings"
                      :key="i"
                      :value="i.value"
                    >
                      {{ i.name }}
                    </option>
                  </select>
                </div>
                <div class="mb-3">
                  <label for="exampleInputPassword1" class="form-label">
                    {{ $t("coupons.execlude_products") }}
                  </label>
                  <select
                    class="form-select"
                    aria-label="Default select example"
                    v-model="coupon.execlude_products"
                  >
                    <option
                      :selected="i.value == 1"
                      v-for="i in hasShippings"
                      :key="i"
                      :value="i.value"
                    >
                      {{ i.name }}
                    </option>
                  </select>
                </div>
                <div
                  class="modal-footer"
                  :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'"
                >
                  <button
                    type="submit"
                    class="btn btn-primary"
                    data-bs-dismiss="modal"
                  >
                    {{ $t("popups.add") }}
                  </button>
                  <button
                    data-bs-dismiss="modal"
                    type="button"
                    class="btn btn-danger"
                  >
                    {{ $t("popups.cancel") }}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--end edit customer-->

    <!-- Button trigger modal -->

    <!--end-->
  </Layout>
</template>

<style scoped>
.btn-close {
  position: relative;
  right: 317px;
  top: 6px;
  font-size: 15px;
}
</style>
